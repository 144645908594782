
.challenge-modifier label {
    display: block;
    margin-bottom: 10px;
    margin-left: 10px;
}

.challenge-modifier input[type="checkbox"] {
    margin-right: 5px;
    background-color: var(--main);
}

.challenge-modifier input[type="number"] {
    border: 1px solid white; /* Match input border styles */
    border-radius: 4px;
    padding: 5px;
    margin: 0 5px;
    width: 50px; /* or 'auto' to match other inputs on the page */
}

.challenge-modifier input[type="number"]:disabled {
    background-color: var(--light); /* A light grey to indicate disabled input */
    color: #999; /* Greyed out text for disabled input */
}

.challenge-modifier input[type="number"]:focus {
    border-color: var(--main); /* Focus color to match the page's mui_theme */
    outline: none;
}

/* For Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@keyframes slideInChallengeModifier {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}