/* page_header.css */
/* Styling for the page header */

.header-container {
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    width: 100%;
    height: 4rem;
    position: fixed;
    /*margin-left: 4rem;*/
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--header-color-light);
    z-index: 110;
    font-family: var(--font-family);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    @media (max-width: 768px){
        /*gap: 3rem;*/
        justify-content: space-between;
    }
}

.logo-container {
    display: flex;
    justify-content: center; /* Horizontally center the logo */
    align-items: center; /* Vertically center the logo */
    width: 4rem; /* Take full width of sidebar */
    height: 4rem; /* Set an appropriate height to fit the logo */
    overflow: hidden; /* Prevent overflow in case the logo exceeds the container */
}

.header-logo {
    height: 80%; /* Maintain aspect ratio */
    width: auto;
    object-fit: contain;
}

/*.hide-header {*/
/*    top: -10vh;*/
/*}*/

/*.move-header {*/
/*    margin-left: 16rem;*/
/*    transition: 0.2s;*/
/*}*/

/*.unmove-header {*/
/*    margin-left: 6rem;*/
/*    transition: 0.2s;*/
/*}*/

@media (max-width: 768px), (pointer: none), (pointer: coarse) {
    .move-header {
        margin-left: 0;
    }
}

.title-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.words {
    color: var(--text-dark);
}


.light {
    background-color: var(--header-color-light);
    color: var(--text-dark);
}

.dark {
    background-color: var(--header-color-dark);
    color: var(--text-light);

}

.title-text {
    font-size: 1.5rem;
    margin: 0;
    display: flex;
    align-items: center;
}

.dropdown-arrow {
    font-size: 14px; /* Make the arrow smaller */
    display: inline;
    position: relative; /* Position the arrow relative to its parent */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.version-select {
    display: flex;
    width: auto;
    height: 2rem;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    padding: 0 0.5rem 0 0.7rem;
    font-size: 1rem;
    margin: 0 1rem 0 1rem;
    background-color: var(--header-color-light);
    color: var(--text-dark);
    border: 2px solid var(--tertiary);
    border-radius: 1rem; /* or 10px for rounded rectangle look */
    outline: none;
    cursor: pointer;
    gap: 0.5rem;
}


/*.version-select:hover {*/
/*    border-color: var(--light);*/
/*}*/


.header-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 2001;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 8px;
    cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item:focus { /* Added focus for accessibility */
    background-color: #e0e0e0;
}

.title-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    height: 100%;
    /*background-color: white;*/
}

.profile{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.user-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    margin: 0 1rem 0 0;
    border: 2px solid var(--tertiary);
    border-radius: 100px;
    width: 3.3rem;
    height: 80%;

    @media (max-width: 768px), (pointer: none), (pointer: coarse) {
        height: 2rem;
        width : 2rem;
    }
}

.user-profile:hover {
    background-color: #cccccc;
}

.profile-image {
    width: 4rem;
    height: 3rem;
    object-fit: fill;

    @media (max-width: 768px), (pointer: none), (pointer: coarse) {
        width: 2rem;
        height: 2rem;
    }
}

.dropdown-icon {
    margin-right: 0.4rem;
}

/*.user-profile-menu{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.mode {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #eef1f6;
    border-radius: 8px;
    gap: 4px;
    width: 80%;
    height: 2rem;
    margin-left: 0.8rem;
    padding: 0 0.9rem;
    box-sizing: border-box;
    cursor: pointer;
    /*transition: background-color 0.3s ease;*/
}


.mode-span {
    padding-right: 0.2rem;
}

.light-m {
    color: var(--text-dark);
}

.dark-m {
    color: var(--text-light);
}

.icon {
    font-size: 1rem;
    stroke-width: 2px;
}

.light-mode, .dark-mode, .mode-transform {
    display: flex;
    /*padding: 0 0.2rem;*/
    justify-content: center;
    align-items: center;
    flex: 1;
    /*width: 50%;*/
    height: 100%;
}

.mode-transform.l{
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: white;
    /*box-shadow: -2px 0 0 rgba(0, 0, 0, 0.1) inset;*/
}


.mode-transform.d{
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #20242B;
    /*animation: slideInLeft 400ms ease-out forwards;*/
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) inset;*/
}


.separator-line { /* Corrected for use with a div */
    margin: 10px 0;
    border: none;
    border-top: 1px solid #ccc;
}

/* Responsive adjustments for smaller screens*/
@media (max-width: 768px), (pointer: none), (pointer: coarse) {
    .title-text {
        font-size: 1.2rem;
    }
}

.burger-mobile {
    padding: 4vh 2vw;
    cursor: pointer;
}
