
.MuiBottomNavigation-root {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.MuiBottomNavigationAction-root {
    min-width: 30px !important;
}

.mobile-navigation {
    display: flex;
    flex-direction: row; /* Stack items vertically */
    align-items: center;
    justify-content: space-between;
    top: auto;
    bottom: -1px;
    position: fixed; /* Stay in place even when scrolling */
    height: 4rem;
    width: 100%; /* Sidebar width */
    background-color: var(--header-color-light); /* Background from CSS variable */
    font-weight: 400;
    font-size: 1.03rem;
    border-radius: 2rem 2rem 0 0;
    color: var(--text-light); /* Text color from CSS variable */
    z-index: 101; /* Ensure sidebar is above other elements */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    gap: 2rem;
}

.mobile-nav-icon{
    color : black;
    font-size: 2rem;
    width: auto;
}