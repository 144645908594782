.canvas-display {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 60px;
    gap: 10px;
}
.editNonogramTitle{
    text-align: center;
}
@media (max-width: 768px), (pointer:none), (pointer:coarse) {
    .canvas-display {
        flex-direction: column;
    }
}

.nonogram-generator-container {
    align-content: center;
    padding: 20px;
}

.upload-file-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-input {
    display: none;
}

.upload-button {
    margin: 20px auto 0;
    display: block;
}

.pixel-art-size-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
}

.size-button {
    margin-left: 10px;
    padding: 10px;
    background-color: var(--main);
    color: var(--light);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.size-button.active {
    background-color: var(--main-hover);
}

.same-size-checkbox {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
}

.canvas-display {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.hidden-canvas {
    display: none;
}

.slider-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 0 5rem;
}

.challenge-name-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
}

.challenge-name-input {
    max-width: 20rem;
    margin-right: 10px;
}

.post-button {
    color: var(--light);
    background-color: var(--main);
    padding: 10px;
    cursor: pointer;
}

.post-button.invalid {
    background-color: red;
}


.nonogram-list{
    max-height: 500px;
    min-height: 500px;
    overflow-y: auto
}

.existing-nonograms-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
}

.nonogram-status{
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    gap: 10px;
}

.nonogram-select{
    background-color: #1976d2 !important;
}
.nonogram-schedule{
    background-color: rgb(27, 94, 32) !important;
}
.nonogram-reject{
    background-color: red !important ;
}