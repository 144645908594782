/* Loading Screen Style */
.loading-screen {
    position: fixed; /* Ensure the loading screen is fixed relative to the viewport */
    top: 0; /* Start from the very top of the screen */
    left: 0; /* Start from the very left of the screen */
    width: 100%; /* Cover the full width of the viewport */
    height: 100%; /* Cover the full height of the viewport */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99; /* Make sure it's on top of everything */
}

/* Loading Component Style */
.loading-component {
    position: relative; /* Changed from fixed to relative */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

/* Loading_icon Style */
.loading-icon {
    width: 30%; /* Adjust the width and height based on your logo size */
    height: 40%;
    animation: rotate 6s linear infinite;
    margin: 5%;
}

/* Loading_spinner Style */
.loading-spinner {
    border: 5px solid var(--light); /* Circle color */
    border-top: 5px solid var(--dark); /* Spinner color */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: rotate 1.2s linear infinite;
    margin: 10%;
}

/* Loading Text Style*/
.loading-text {
    color: var(--light); /* White text */
    font-size: 18px;
    margin-top: 10px; /* Space between spinner and text */
    margin-bottom: 2%;
}

/* Keyframes for Animation */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


