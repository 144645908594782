@import '../../App.css';

.default_component_style {
    margin-left: 0 !important;
}

.default_component_style hr {
    border: 1px;
    clear: both;
    display: block;
    margin-left: 0 !important;
    background-color: var(--main);
    height: 1px;
}

/*.component_header {*/
/*    background-color: var(--main);*/
/*    border: 1px solid var(--light);*/
/*    border-color: var(--light);*/
/*    padding-top: 10px;*/
/*    padding-bottom: 10px;*/
/*}*/

.component_title {
    margin-left: 0 !important;
    text-align: left;
}

.square-border {
    border-radius: 0;
}

.input-button-match {
    display: inline-block;
    height: 1.18rem;
    /* width: 1rem; */ /* Remove or increase this value */
    text-align: center;
    background-color: var(--light);
    color: var(--dark);
    border: 1px solid var(--main);
    border-radius: 0;
    padding: 0 10px; /* Ensure this is equal on both sides */
    cursor: pointer;
    outline: none;

}

.date-picker {
    display: flex;
    right: 2vw;
    height: 20px;
    width: 7rem;
    font-family: var(--font-family);
    border: 1px solid var(--light);
    color: var(--main);
    border-radius: 3px;
    cursor: pointer;
}


.input {
    display: inline-block;
    height: 47px;
    border: 1px solid var(--light);
    border-radius: 5px
}

.input-2 {
    display: inline-block;
    height: 1.2rem;
    border: 1px solid var(--light);
    border-radius: 5px;
}

.date-picker-container {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    margin-right: 10px;
    color: var(--main);
}

.big-input-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.big-input-list textarea {
    border-radius: 5px;
    background: var(--light);
    border: 1px solid var(--dark-gray);
    width: 100%;
    height: 50px;
    margin: 5px;
    resize: vertical; /* Allows the user to resize the input field vertically */
    text-align: left; /* Aligns the text to the left */
    overflow-wrap: break-word;
}

/*.file-input {*/
/*    display: inline-block;*/
/*    padding: 10px 15px;*/
/*    background-color: var(--main);*/
/*    color: var(--light);*/
/*    border: none;*/
/*    border-radius: 5px;*/
/*    cursor: pointer;*/
/*    text-align: center;*/
/*    font-size: 16px;*/
/*    transition: background-color 0.3s ease;*/
/*}*/

/*.file-input:hover {*/
/*    background-color: var(--main);*/
/*}*/