.user-manager {
    padding-top: 20px;
}

.user-manager-header {
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    margin-bottom: 15px;
}

.user-manager-header h2 {
    /*margin: 0 20px;*/
    font-size: 24px;
    /*position: relative;*/
    /*z-index: 1; !* Ensure text is above the lines *!*/
    /*background: #fff; !* Match this to your background color *!*/
    /*padding: 0 10px; !* Optional: adds padding around the text *!*/
}

.stack {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 2rem;
    margin: 0;
    padding-right: 5px;
    height: auto;
}

/*.user-manager-header::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    width: 100%;*/
/*    height: 1px;*/
/*    background: #ccc;*/
/*    z-index: 0; !* Ensure lines are behind the text *!*/
/*}*/

.form-section {
    margin-top: 20px;
}

.input {
    height: 3rem;
    width: 190px;
    padding-left: 10px;
    border: 1px solid var(--background-dark);
    transition: border-color 0.25s ease-out; /* Add transition effect */
    overflow: hidden;
    border-radius: 8px;
    /*color: var(--text-light);*/
}

.change-password-container{
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.add-user-container{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.light-border{
    border: 1px solid var(--background-dark);
    background-color: var(--header-color-light);
    color: var(--text-dark);
    font-weight: 500;
}

.dark-border{
    background-color: var(--header-color-dark);
    border: 1px solid var(--background-light);
    color: var(--text-light);
    font-weight: 500;
}
