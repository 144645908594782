@import '../../App.css';

.dashboard {
    display: flex;
    overflow-x: auto;
    flex-direction: column;
    justify-content: center; /* Centers the children vertically */
    align-items: center; /* Centers the children horizontally */
    width: 100%; /* Take full width to center metrics properly */
    height: 11rem;
    background: transparent;

}

.metrics::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.metrics {
    display: flex; /* Changes from grid to flex to keep items in one row */
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto ; /* Allows horizontal scrolling if items overflow */
    white-space: nowrap; /* Prevents items from wrapping to a new line */
    text-align: center;
    margin: 0 auto; /* Centers the items */
    width: auto; /* Allows the items to grow to the right */
    gap: 7px;
}

.metric {
    flex: 0 0 auto; /* Prevents the items from growing or shrinking */
    /*border-left: 1px solid var(--dark-gray); !* Light gray border on the left side of the metric cards *!*/
    padding: 0 10px;
    min-width: 170px; /* Ensures a minimum width for each item */
    background-color: rgba(255, 255, 255, 0.06);
    border: 1px solid transparent;
    border-radius: 8px;
}

.metric:first-child {
    border-left: none; /* Removes the left border for the first item for aesthetic balance */
}

/*.metric:last-child {*/
/*    border-right: 1px solid var(--dark-gray); !* Light gray border on the right side of the last metric card *!*/
/*}*/

.metric h2 {
    font-size: 0.8rem; /* Smaller font size for the metric title */
    text-transform: uppercase; /* Uppercase text for the metric title */
    font-weight: bold; /* Normal font weight for the metric title */
    margin: 0.5rem 0; /* Removes margin from all sides */
}

.metric p {
    font-size: 2rem; /* Larger font size for the metric number */
    font-weight: bold; /* Bold font weight for the metric number */
    margin: 0.5rem 0; /* Ensures consistent spacing around the text */
}

/*.light{*/
/*    background-color: var(--background-light);*/
/*    color: var(--text-dark);*/
/*}*/

/*.dark{*/
/*    background-color: var(--background-dark);*/
/*    color:var(--text-light);*/

/*}*/

@media (max-width: 600px), (pointer:none), (pointer:coarse) {
    .metric {
        min-width: 150px; /* Reduce min-width for small screens */
        padding: 10px; /* Reduce padding */
    }
}