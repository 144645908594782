@import '../../App.css';

.table {
    border-collapse: collapse;
    width: 100%;
    font-family: var(--font-family);
    font-size: 16px;
    background: var(--light);
    padding: 10px;
    margin-bottom: 0 !important
}

.table_header {
    background-color: var(--main);
    color: var(--light);
}

.table th, .table td {
    text-align: left;
    font-weight: normal;
    padding-bottom: 5px;
    padding-top: 5px;
}

.table th {
    background-color: var(--main);
    color: var(--light);
    padding: 10px;
}

.table-row {
    text-align: left;
    font-weight: normal;
    padding-bottom: 5px;
    padding-top: 5px;
}

.table_input_box {
    border: 0;
    font-family: var(--font-family);
    font-size: 16px;
    width: 100%;
    background-color: white;
}

.table_input_box-2 {
    border: 0;
    font-family: var(--font-family);
}

.table_datepicker_input {
    border: 0;
    width: 110%;
    font-family: var(--font-family);
    font-size: 16px;
    z-index: 4000;
}

.table_button {
    border: 0;
    background-color: transparent;
    color: var(--main);
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    user-select: none;
}

.table_add_button {
    border: 0;
    color: var(--main);
    padding-top: 5px;
    padding-left: 6px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    user-select: none;
}

.table_button:hover {
    color: var(--header-color);
}

.table_add_button:hover {
    color: var(--main-hover);
}



.table tr:hover {
    border-bottom: 1px solid #D1DFFC !important;
    border-top: 1px solid #D1DFFC !important;
    background-color: var(--grey-hover) !important;
}



.table tr {
    border-top: 1px solid var(--dark-gray);
}

.selected {
    border-bottom: 1px solid var(--dark-gray) !important;
    border-top: 1px solid var(--dark-gray) !important;
    background-color: var(--light-blue);
    color: var(--dark);
}

.selected .table_button {
    color: var(--main);
}

.selected .table_button:hover {
    color: var(--header-color);
}

.selected .table_datepicker_input {
    background-color: var(--light);
    color: var(--main); /* Dark text to ensure readability */

}

.table-2 {
    border-collapse: collapse;
    width: 100%;
    font-family: var(--font-family);
    font-size: 16px;
    padding: 10px;
}

.table-2 th {
    background-color:  var(--dark-gray);
}

.table-2 th, .table-2 td {
    border-top: 1px solid var(--dark-gray);
    text-align: left;
    font-weight: normal;
    padding: 5px;
    border-right: 1px solid var(--dark-gray);
}

.table-2 td:last-child {
    border-right: none;
}

.table-2 th:first-child {
    border-left: 1px solid var(--dark-gray);
}

.table-2 tr:hover {
    background-color: var(--light);
}

.table-3 {
    border-collapse: collapse;
    width: 25%;
    font-family: var(--font-family);
    font-size: 16px;
}

.table-3 td:first-child {
    text-align: center;
    padding: 5px 0 0 0;
    background-color: var(--main);
}

.dateColumn {
    width: 10%; /* Adjust as needed */
}

.normalColumn {
    width: 7%; /* Adjust as needed */
}

.fontMono {
    font-family: var(--font-family);
    font-size: 16px;
}


