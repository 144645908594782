.existing-sudotetris-viewer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.sudotetris-schedule {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
}

.sudotetris-list-container {
    max-height: 500px;
    min-height: 500px;
    overflow-y: auto;
}
