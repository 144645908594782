/* connections.css */

/* Overall container styling */
.connections {
    font-family: Arial, sans-serif;
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
}

/* ---- Puzzle Grid ---- */
.puzzle-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
}

.puzzle-cell {
    background-color: #f2f2eb; /* Slightly off-white */
    border-radius: 6px;
    border: 1px solid #e3e3dd;
    padding: 1rem;
    text-align: center;
    font-weight: 600;
    color: #000; /* Black text */
    font-size: 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    /* Ensures consistent size across cells */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ---- Solution Preview ---- */
.solution-preview {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: center;
    /* optional: constrain overall width and center on page */
    max-width: 800px;
    margin: 0 auto;
}

/* Each group container */
.solution-group {
    /* Use text-align:center to center heading/words */
    text-align: center;
    padding: 0 0 1rem 0;
    border-radius: 6px;
    color: #000; /* Ensure text is black */
    font-family: sans-serif; /* Or your preferred font */
}

/* Distinct color backgrounds for each group */
.group-yellow {
    background-color: #F9DF6D; /* Pastel yellow */
}
.group-blue {
    background-color: #B0C4EF; /* Pastel blue */
}
.group-green {
    background-color: #A0C35A; /* Pastel green */
}
.group-purple {
    background-color: #BB81C5; /* Pastel purple */
}

/* The “Theme” heading inside each group */
.solution-group p {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
}

/* Word list styling */
.solution-group ul {
    list-style: none;     /* Remove default bullets */
    margin: 0 0 0;   /* A little spacing above the list */
    padding: 0;
    display: flex;        /* Lay words out horizontally */
    flex-wrap: wrap;      /* Wrap to next line if too many words */
    justify-content: center;
    gap: 1rem;            /* Spacing between words */
}

/* Individual words */
.solution-group li {
    font-size: 1.3rem;
    font-weight: 300;
    /* Optional if you want the words in uppercase too:
    text-transform: uppercase;
    */
}


/* Error message styling */
.error-message {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid red;
    background-color: #ffe6e6;
    color: #a00;
    border-radius: 4px;
}

/* ---- New Styles for Centering Titles and Upload Button ---- */

/* Centering the titles */
.title {
    text-align: center;
    margin-bottom: 1rem; /* Adds spacing below the title */
}

/* Upload button container */
.upload-button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

/* Optional: Styling the Upload button */
.upload-button-container button {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    background-color: #562771; /* Green background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.upload-button-container button:hover {
    background-color: #2D1337; /* Darker green on hover */
}

/* ---- New Styles for Custom File Input ---- */

/* Hide the actual file input */
.custom-file-input {
    display: none;
}

/* Style the label to look like a button */
.custom-file-label {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    background-color: #562771; /* Blue background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
    transition: background-color 0.3s ease;
}

.custom-file-label:hover {
    background-color: #2D1337; /* Darker blue on hover */
}

/* Display selected file name */
.selected-file {
    font-size: 1rem;
    color: #333;
    vertical-align: middle;
}
