/* styling for home page */

.home span {
    font-weight: bold;
    font-size: 1.8rem;
    padding-top: 4px;
    text-align: center;
    /*box-sizing: border-box;*/
}

.home h2 {
    font-weight: bolder;
    font-size: 3rem;
    text-align: center;
}

.home h4 {
    font-weight: bolder;
    font-size: 2rem;
    text-align: center;
    padding-top: 4px;
}

.percentage.green {
    color: #00ff11;
}

.percentage.red {
    color: #ff2929;
}

.home {
    display: flex;
    background: var(--background1);
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.log-in {
    display: flex;
    width: 100%;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2px;
    justify-content: center;
    align-items: center;
}

.background-image {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 9.5vh;
}

.login-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100%;
    flex-direction: row;
    background-color: #272C34;
}

.section1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
    height: 100%;
    border-radius: 0 8rem 8rem 0;
    background: var(--background1);

    @media (max-width: 600px) {
        width: 0;
        height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
    }
}


.denda {
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    font-family: var(--font-family);
    padding: 1.5rem;
}

.login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
    margin-bottom: 2rem;
}

.logo {
    height: auto;
    width: 550px;
}

.welcome-message-login {
    display: flex;
    margin: 0.1rem 0 30px 0;
    justify-content: center;
    align-items: center;
    padding: 0 1.4rem;
    font-size: 1rem;
    font-weight: lighter;
    color: white;
    font-family: var(--font-family);
}

.section2 {
    display: flex;
    background-color: #272C34;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100%;
    padding: 0;
    margin: 0;
    flex-direction: column;

    @media (max-width: 600px) {
        width: 100%;
        background-color: var(--background1);
    }
}

.dashb, .kpi-container, .topg-container, .dashb-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    /*flex: 1;*/
}

/*.dashb-container{*/
/*    padding: 0 2rem;*/

/*}*/

.dashb {
    flex-direction: column;
    width: 100%;
    height: 30rem;
    border-radius: 5rem;
    margin-top: 2rem;
    background: rgba(255, 255, 255, 0.1);
    padding: 0 2rem;
    color:var(--text-dark);

}

.kpi-container, .topg-container, .dashb-container {
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
    padding: 0 2rem;
    /*flex-wrap: wrap;*/
    color: white;
}

.kpi-container{
    flex-direction: row;
 }

.topg-container {
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2rem;
}

.kpi {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    height: 15rem;
    width: 15rem;
    border-radius: 3rem;
    margin-top: 1rem;
    flex: .5;
    background: rgba(255, 255, 255, 0.1);
    flex-direction: column;
}


.topg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 52rem;
    width: 26rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5rem;
    margin-top: 1rem;
    flex-direction: column;
    flex: 1;
    color: white;
}

.welcome-message {
    display: flex;
    /*flex-direction: row;*/
    justify-content: flex-start;
    color: white;
    font-family: var(--font-family);
    word-wrap: break-word;
    margin-top: 18rem;
    margin-right: 70rem;
    font-style: italic;
    max-width: 35vw;
    flex-wrap: wrap;
}

/*.welcome-quote{*/
/*    font-weight: bold;*/
/*    font-size: 8rem;*/
/*    !*padding-top: 4px;*!*/
/*    text-align: center;*/
/*}*/

.hide-mobile {
    display: none;
}

@media (max-width: 768px), (pointer: none), (pointer: coarse) {
    .hide-desktop {
        display: none;
    }

    .hide-mobile {
        display: block;
    }

    .welcome-message {
        font-size: 5vh;
        padding-top: 10vh;
        padding-left: 2vw;
        max-width: 100%;
        margin-right: 0;
    }
}

.welcome-bold {
    font-weight: 600;
}

.welcome-quote {
    font-weight: 500;
}


/* Floating animation for phones */
@keyframes floatAnimation {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.phone-image {
    height: 50vh;
    width: 35vw;
    position: absolute;
    animation: floatAnimation 3s ease-in-out infinite;
    object-fit: contain;
    display: flex;
    flex-direction: column;
}

.phone-image-top {
    top: 18vh;
    right: 5vw;
    z-index: 3;
}

.phone-image-middle {
    top: 23vh;
    right: 4vw;
    z-index: 2;
    mix-blend-mode: color-dodge;
}

.phone-image-bottom {
    top: 28vh;
    right: 3vw;
    z-index: 1;
    mix-blend-mode: color-dodge;
}

