.folder-row {
    margin-bottom: 20px;
}

.image-row {
    background: var(--bubble);
    border-radius: 1%;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the space between images */
}

.image-container {
    /* Adjust the width as per requirement, this controls the number of images per row */
    width: 7%;
    height: 7%;
}

.tiles-canvas {
    display: block;
    margin: 10px auto 10px auto;
}