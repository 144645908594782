/*login form css*/
/*styles for the login form*/

@import '../../../App.css';

.login-container {
width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px; /* Adjust this value to center vertically as needed */
    padding: 20px;
    border-radius: 4px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
}

.login-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.login-form .form-group {
    margin-bottom: 10px;

}

.login-form .form-group input {
    width: 190px;
    padding: 10px;
    border: 1px solid var(--grey);
    transition: border-color 0.25s ease-out; /* Add transition effect */
    overflow: hidden;
    border-radius: 8px;
    color: var(--light);
}

.login-form .form-check {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form .form-check input {
    margin-right: 5px;
}

.login-form .form-check label {
    margin: 0;
}

.login-button {
    display: block;
    width: 170px; /* Adjust as needed */
    padding: 10px;
    border: none;
    border-radius: 8px;
    background-color: var(--main);
    color: white;
    cursor: pointer;
}

.login-button:hover {
    background-color: var(--main-hover);
}

.logout-button {
    display: block;
    width: 172px; /* Adjust as needed */
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: var(--red);
    color: white;
    cursor: pointer;
}

.logout-button:hover {
    background-color: var(--red-hover);
}

.login-button.thomas-button {
    margin-top: 10px; /* Adjust the space as needed */
}

.login-button.thomas-button:hover {
    background-color: var(--main-hover);
}

.button-container {
    width: 172px;
    display: flex;
    justify-content: space-between; /* Adjust as needed */
}

.baby-button {
    display: block;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: var(--main);
    color: white;
    cursor: pointer;
    width: 75px; /* Adjust as needed */
    margin-top: 10px; /* Adjust the space as needed */
}

.baby-button.toggled {
    background-color: var(--grey);
}


.input-error {
    border: 1px solid red !important;
    transition: border-color 0.25s ease-out; /* Add transition effect */
}

@keyframes fadeOut {
    0% {
        color: red;
    }
    100% {
        color: transparent;
    }
}

.error-message {
    animation: fadeOut 5s forwards;
    color: red;
}

.input-disabled {
    background-color: #e0e0e0; /* Light gray background */
    color: #a0a0a0; /* Dimmed text color */
    border: 1px solid #c0c0c0; /* Optional: adds a border to the input */
    cursor: not-allowed; /* Shows a 'not-allowed' cursor on hover */
}

/* Animation for the welcome message */
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.welcome-message-login {
    margin: 0.1rem;
    justify-content: center;
    padding: 0 1.4rem;
    font-size: 2rem;
    font-weight: 400;
    /*animation: fadeInLeft 1s ease-out forwards;*/
}

/* Animation for the login form */
@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-100%);
    }
}

/*.login-form {*/
/*    animation: slideInLeft 1s ease-out forwards;*/
/*}*/

/* login container holder here */
.login-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}



