@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --dark-gray: #D3D3D3;
    --sidebar: #3A3A3A;
    --menu: rgba(255, 255, 255, 0.3);
    --menu-hover: rgba(255, 255, 255, 0.2);
    --bubble: #E8E8E8;
    --light-blue: #D1DFFC;
    --button: #2D1337;
    --red: #C94940;
    --red-hover: #AC3931;
    --main: #562771;
    --main-hover: #2D1337;
    --header-color-light: #FFFFFF;
    --header-color-dark: #272C34;
    --background-dark: #20242B;
    --background-light: #f0f2f6;
    --text-dark: #272C34;
    --text-light: white;
    --background-light2: #f7f7f7;
    --light: white;
    --secondary: #962161;
    --secondary-hover: #7A1A4E;
    --tertiary: #b19cd9;
    --tertiary-hover: #9173b2;
    --grey: #E8E8E8;
    --grey-hover: #D3D3D3;
    --color: #8472ED;
    --accent-colour: #7352a7;

    --dark: #000;
    --background1:  linear-gradient(195deg, rgba(233,223,223,1) 0%, rgba(157,143,243,1) 30%, rgba(86,39,113,1) 100%) no-repeat center center fixed !important;
    --background2: linear-gradient(180deg, var(--main) 0%, var(--secondary) 100%) no-repeat center center fixed !important;
    --font-family: Inter, sans-serif;
}


body {
    margin: 0;
    min-height: 100vh;
    /*color: var(--light);*/
}

body, html {
    width: 100vw;
    /*height: 100%;*/
    overflow-x: hidden;
}

body.theme-light {
    background:
            linear-gradient(
            to bottom,
            /*rgba(233, 223, 223, 1) 0%,*/
            rgba(157, 143, 243, 1) 0%,
            rgba(86, 39, 113, 1) 13%,
            var(--background-light) 13%,
            var(--background-light) 100%
    ) no-repeat scroll ;

    /*background-color: var(--background-light) !important;*/
    color: var(--text-dark) !important;
}

body.theme-dark{
    background: linear-gradient(
            to bottom,
            /*rgba(233, 223, 223, 1) 0%,*/
            rgba(157, 143, 243, 1) 0%,
            rgba(86, 39, 113, 1) 13%,
            var(--background-dark) 13%,
            var(--background-dark) 100%
    ) no-repeat scroll;

    /*background-color: var(--background-dark) !important;*/
    color: var(--text-light) !important;
}

.app, .page-container {
    display: flex;
    min-height: 100vh;
    max-height: fit-content;
    /*background-color: var(--background-light);*/
}



/*@media (max-width: 768px), (pointer:none), (pointer:coarse) {*/
/*    body {*/
/*        padding-top: 100px; !* Adjust based on the header's height at this viewport size *!*/
/*    }*/
/*}*/


