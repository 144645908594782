
body {
  margin: 0;
  font-family: "Open Sans", system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

code {
    font-family: "Source Code Pro", Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

