.game-names h2{
    margin-bottom: 0.3rem;
}


/*.games-list{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    !*justify-content: space-evenly;*!*/
/*    !*align-items: center;*!*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    !*background: #efefef;*!*/
/*}*/

.denda-games{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 10rem;
    object-fit: contain;
    position: absolute;
    top : 0;
}
.header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 116px;
    object-fit: cover;
    box-sizing: border-box;
    background-color: transparent;
}

.content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    flex: 1;
    flex-direction: row;
}

.game-name-container{
    display: flex;
    /*justify-content: flex-start;*/
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0.6rem;
}

.game-names{
    display: flex;
    justify-content: space-between;
    background-color: var(--light);
    align-items: center;
    width: 100%;
    height: 3rem;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    gap: 1rem;
    margin-top: 1rem;
}

.subpage-drawer{
    display:flex;
    align-items: center;
    border-radius: 15px;
}

.drawer-heading{
    padding: 0.6rem 0 0 0.6rem;
    text-align: start;
    font-size: 2rem;
    font-weight: bold;
}

.drawer-list{
    display: flex;
    font-size: 2rem;
}

.drawer-divider{
    background-color: var(--dark);
    width: 100%;
    height: 1px;
}