.first_col {
    flex: 0 0 25%; /* This sets the first column to take up 25% of the row */
}

.second_col {
    flex: 1; /* This makes other columns take up the remaining space */
}

.graph-container {
    width: 100%;
    height: 100%;
}

.four-graph-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/*.retention-Date{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.date-label{
    font-size: large;
    font-weight: 700;
}

.date{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    background-color: var(--main);
    border-radius: 8px;
    border: 1px solid transparent;
    color: white;
    font-weight: 700;
    padding: 0 0 0 1rem;
}

@media (max-width: 600px), (pointer: none), (pointer: coarse) {
    .first_col {
        flex: 0 0 100%; /* This sets the first column to take up 100% of the row */
    }

    .second_col {
        flex: 1; /* This makes other columns take up the remaining space */
    }

    .four-graph-container {
        flex-direction: column;
    }
}

.small-graph-container {
    width: 100%;
    height: 100%;
    flex-basis: 50%;
    box-sizing: border-box;
}

.statistics-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
}

.plot-container {
    width: 100%;
}

.options-container {
    width: 100%;
    margin-top: 20px; /* Add some space between the plot and the options */
}

.csv-download-button {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 10px;
}

/* When on smaller screens, adjust layout accordingly */
@media (max-width: 768px), (pointer: none), (pointer: coarse) {
    .plot-container {
        order: 2; /* Plot goes below options */
    }

    .options-container {
        order: 1; /* Options go above plot */
    }
}